import React from "react";
import { Link } from "gatsby";
import { toTitleCase, replaceAll } from "../../utils/common";

export default function PlatformsNavigation({ allPlatforms, platformDisplayNameSettings, currentPlatform }) {
    allPlatforms = allPlatforms || [];

    const getPlatformDisplayName = (platformName) => {
        if (platformDisplayNameSettings && platformDisplayNameSettings[platformName])
            return platformDisplayNameSettings[platformName];
        return toTitleCase(replaceAll(platformName, '_', ' ')).trim();
    }

    const getPlatformUrl = (platformName) => {
        let platformUrl = platformName;
        if (platformDisplayNameSettings[platformName]) {
            platformUrl = platformDisplayNameSettings[platformName].trim().toLowerCase().split(' ').join('-');
        }
        return `/integration/${platformUrl}-supported-metrics-dimensions`;
    }

    allPlatforms = allPlatforms.sort((a, b) => getPlatformDisplayName(a).localeCompare(getPlatformDisplayName(b)));
    return (
        <React.Fragment>
            <nav role="navigation">
                <ul className="integration-navigation">
                    <span className="text-bold">Platforms</span>
                    {allPlatforms.map((platformName) => (
                        <li>
                            <Link className={`${platformName === currentPlatform ? "integration-platform-link-active" : "integration-platform-link"}`} to={getPlatformUrl(platformName)}>{getPlatformDisplayName(platformName)}</Link>
                        </li>
                    )
                    )}
                </ul>
            </nav>
        </React.Fragment>
    );
}
