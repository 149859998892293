import React from "react";
import PlatformsNavigation from "../components/integration/platform-navigation";
import { Helmet } from "react-helmet";
import Layouts from "../layouts";
import Section from "../components/product/productSection";
import { resetWebflow } from "../utils/common";

export default class IntegrationSupportedFieldHomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        };
    }

    showMenu = () => {
        const { showMenu } = this.state;
        this.setState({ showMenu: !showMenu });
    };

    render() {
        resetWebflow("5aabd62c51e22e4f80346d1c");
        let {
            allPlatformsNames,
            platformDisplayNameSetting,
            homePageHeading,
            homePageSubHeading,
            homePageBody,
            homePageImage
        } = this.props.pageContext;

        let sectionData = {
            title: homePageSubHeading,
            subtitle: homePageBody,
            image: {
                url: homePageImage.url,
                alt: homePageHeading
            }
        };
        let sectionProps = {
            sectionClass: "left-align",
            containerClass: "revert",
            h2Class: "h2 content-left",
            pClass: "content-left",
            imageClass: "right",
            imageShadowClass: true
        };

        return (
            <Layouts>
                <Helmet>
                    <title>Full List of Supported Metrics and Dimensions | Nugit Data Storytelling</title>
                </Helmet>
                <div className="flex-container integration-supported-fields-container align-items-start">
                    <div class="hamburger-menu" onClick={this.showMenu}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={`flex w-20pc w-80pc-mobile mr-4 border-right ${this.state && this.state.showMenu ? "" : "integration-navigation-container"}`}>
                        <PlatformsNavigation allPlatforms={allPlatformsNames} platformDisplayNameSettings={platformDisplayNameSetting}></PlatformsNavigation>
                    </div>
                    <div className="integration-supported-fields-grid-container">
                        <h1>Supported Fields from Third-Party Platform Integrations</h1>
                        <Section data={sectionData} props={sectionProps}></Section>
                    </div>
                </div>
            </Layouts>
        );
    }
}